import saleInput from './sale/index.vue'
import channelInput from './channel/index.vue'
import {mapState} from "vuex";
export default {
  name: "ChannelInput",
  components: {
    saleInput,
    channelInput
  },
  data() {
    return {
      type:'saleInput',
    };
  },
  computed: {
    ...mapState({
      isFenxiaoOrder: state => state.user.userInfo.account.marketModels,
      toPath: state => state.user.toPath
    })
  },
  methods:{
    getCustromType(){
      // 17451是渠道采购，17452 自营或者17453 海信采购 （可能同时存在）
     // 自营
     if (this.isFenxiaoOrder.indexOf("17452") !== -1 &&  this.isFenxiaoOrder.indexOf("17451") == -1 && this.isFenxiaoOrder.indexOf("17453") == -1){
        return 5
      }
      if (
          // 代理
          (this.isFenxiaoOrder.indexOf("17452") !== -1 ||
              this.isFenxiaoOrder.indexOf("17453") !== -1) &&
          this.isFenxiaoOrder.indexOf("17451") == -1
      ) {
        return 1
      } else if (
          //分销
          this.isFenxiaoOrder.indexOf("17451") !== -1 &&
          this.isFenxiaoOrder.indexOf("17452") == -1 &&
          this.isFenxiaoOrder.indexOf("17453") == -1
      ) {
        return 2
      } else if (
          // 分销直营 不显示
          //共存 包括分销或者不包括直营 并且是海信 return 3 显示按钮
          (this.isFenxiaoOrder.indexOf("17451") !== -1 || this.isFenxiaoOrder.indexOf("17452") == -1) && this.isFenxiaoOrder.indexOf("17453") !== -1
      ) {
       return 3
      } else if (this.isFenxiaoOrder.indexOf("17451") !== -1 && this.isFenxiaoOrder.indexOf("17452") !== -1&& this.isFenxiaoOrder.indexOf("17453") == -1){
        return 4
      }



    },
    changeType(type){
      this.type = type
    }
  }
}