// import tableUtil from '@/components/global/TableModal/util' //组件
import { getProductList } from "../components/api";
import Step from "@/components/global/Step/Step.vue";
import ProductList from "../components/ProductList.vue";
import ProductModal from "@/views/stockCenter/adjustmentApply/components/ProductModal.vue";
import axios from "axios";
import { dmsPublicPath } from "@/common/constant.js";
import {
  // 分销商 分销地址
  showAnother,
  // 收货地址切换带出联系人
  // 获取城市
  cityChange,
  // 获取城镇 区
  townChange,
  // 获取城镇区下一级
  getQu,
  // 分销商切换 获取新分销商地址
  getDISAdd,
  // 分销商地址切换请求联系人
  getPhone,
  // dms配送方式
  getDeliveryMode,
  // 查询商品行信息
  // getCustomer,
  findBaseData,
  // 特惠专区接口
  // specialZone,
  getBasicMessage, //零售订单基础信息
  // 零售订单下单
  retailOrder,
  // 获取销售组织list
  getOrgList,
  getProvice,
  findMaterialByStore,
  getSeverList,
  getWarehouseList,
  uploadAttachment,
  getDeliveryType
} from "./api";
import moment from "moment";
import { mapState } from "vuex";
import { isEnableOrNotBucha } from "@/views/stockCenter/adjustmentApply/status/api";
import { getAdressapi } from "@/views/settlement/api";

export default {
  name: "Home",
  components: {
    Step,
    ProductList,
    ProductModal
  },
  props: {
    orderType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      rowSelectionType: "checkbox", //是否编辑
      selRow: {}, //当前编辑的行
      modelShow: false,
      bankChecked: true,
      visible: false,
      steps: ["录入订单", "确认订单信息", "提交订单"],
      currentStep: 2,
      deliveryOptions: [],
      prods: [],
      // 开票户头账号
      choseAccounts: [],
      // 开票户头id
      invoiceId: "",
      //是否允许提前发货
      allowAdvance: true,
      // 用户选择截止时间
      // endTime: '',
      // 经销商下拉选择框
      distr: false,
      // 经销商地址
      distrAddress: "",
      distrAdd: false,
      distrName: "",
      // 经销商地址
      orderDis: [],
      // 选择直配到分销时填写客户单号
      orderNumber: "",
      // 用户详细地址
      userDetailAddress: "",

      // 省市区三级联动
      secondCity: "",
      // 接收子组件选中的商品
      sonChectItme: [],
      receiveAdd: false,
      // 收货地址/统仓地址
      receive: "",
      // 销售组织id
      receiveID: "",
      // 收货地址类型 obj.shareFlag=='Y'?'统仓地址':'收货地址'
      // 配送方式ID
      sendId: "",
      // 分销商地址选择
      distributionAddress: [],
      // 输入输入内容
      searchInt: "",
      // 省
      provices: [],
      //市
      getCity: [],
      // 城镇
      getTown: [],
      // 城镇下一级
      qu: [],
      // 省市区选择
      proObj: {},

      // 经销商地址列表
      choseDistribution: [],
      // 分销商列表
      DisList: [],
      // 经销商及经销商地址
      distributionAdd: {
        DISCODE: "",
        DISADD: ""
      },
      // 经销商切换获取地址 接口参数
      DISADDPARAMS: {
        customerId: "",
        orgId: "",
        matklId: ""
      },
      // 要求到货周
      arriveOptions: [],
      selected: "",
      // 商品数量、体积、价格、预付款
      goodsNum: 0,
      goodsVolume: 0,
      goodsPrice: 0,
      advancePrice: 0,
      TotalPayment: {},

      //返利 （返利选项是否选中）
      //返利按钮是否选中
      isTradeChecked: true,
      // 预付比例
      advancePayRate: 0,
      totalAdvanceMoney: 0,
      drawerName: "",
      dissize: false,
      // 点击提交按钮提交的参数
      orgAndGroup: "",
      orderCartIds: {},
      // 配送方式
      trans: "",
      hisenseOff: true,
      officehis: [],
      officeCode: "",
      receivehis: "",
      officeHis: false,
      orgCode: "",
      matklId: "",
      orgId: "",
      orgtitle: "",
      obj: {},
      // 提交给后台订单详情
      submitGoods: [],
      endTime: "",
      // 有效截止时间
      startTime: "",
      // 后台接口最大截止时间
      maxEndDate: "",
      // 面包屑
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        // {
        //   path: "/cart",
        //   name: "cart",
        //   title: "订单录入"
        // },
        {
          path: "/confirm/channelInput",
          name: "",
          title: "订单录入"
        }
      ],
      // 按钮组件loading
      isLoading: false,
      // 页面加载loading
      pageLoadFlag: false,
      //备注
      BZvalue: "",
      // 收货地址
      shippingAddress: {},
      totalOrderedQty: 0,
      totalAmount: 0,
      purchaseOrderItem: [],

      supplierCode: "",
      // 渠道客户
      business: {
        code: "",
        name: ""
      },
      // 基础信息接口数据
      realObj: {},
      // 销售门店
      XSMD: [],
      receiveAddXS: false,
      receiveXS: "",
      receiveXSID: "",
      // 发货仓库
      receiveFH: [],
      receiveAddFH: false,
      receiveFHS: "",
      receiveFHID: "",
      // 收货地址
      receivehisSH: "",
      // 销售类型
      saleOptions: [
        {
          name: "零售",
          id: "retail"
        },
        {
          name: "工程",
          id: "engineering"
        }
      ],
      XSLX: "retail",
      kpf: "",
      productList: [],
      dataCache: [],
      //组件
      columns: [
        {
          label: "型号",
          prop: "model",
          ellipsis: true,
          width: "120",
          key: "model"
        },
        {
          label: "颜色",
          prop: "colour",
          width: "80",
          key: "colour"
        },
        {
          label: "物料组",
          prop: "materialGroupName",
          width: "100",
          ellipsis: true,
          key: "materialGroupName"
        },
        {
          label: "价格(元)",
          prop: "999",
          width: "100",
          key: "999"
        },
        {
          label: "体积(m³)",
          prop: "volume",
          width: "110",
          key: "volume"
        },
        {
          label: "仓库",
          prop: "warehouseName",
          width: "124",
          ellipsis: true,
          key: "warehouseName"
        },
        {
          label: "质量等级",
          prop: "invStatusName",
          width: "110",
          key: "invStatusName"
        },
        {
          label: "补差类型",
          prop: "invStatusTypeName",
          width: "110",
          key: "invStatusTypeName"
        },
        {
          label: "可用库存",
          prop: "bigQty",
          width: "110",
          key: "bigQty"
        },
        {
          label: "销售数量",
          prop: "",
          key: "borderedQty",
          minWidth: 110,
          slot: 'count'
        }
      ],
      dataList: [],
      isVisible: false,
      tabelIsLoading: false,
      searchIpt: "",
      tableTotal: 0,
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10
      },
      // 城市
      checkCity: "",
      checkTownCode: "",
      checkXZ: "",
      sheng: "",
      materialGroup: [],
      xszhId: "",
      fhckId: "",
      fhckType: "",//仓库类型
      xsmdId: "",
      isEnableOrNot: true,
      TGOptions: [
        {
          name: "是",
          id: "1"
        },
        {
          name: "否",
          id: "0"
        }
      ],
      tgType: '',
      SZOptions: [
        {
          name: "是",
          id: "1"
        },
        {
          name: "否",
          id: "0"
        }
      ],
      szytType: '',
      fwlbId: [],
      // 选中的服务列表
      // serverBox:'',
      // 服务列表数据
      FWlist: [],
      fileList: [],
      ImgIds: [],// 上传后的id

      visibleshop: false,
      visibleshopGC: false,
      visibleshopLS: false,
      show1: false,
      show2: false,
      show3: false,
      show1Message: '',
      show2Message: '',
      show3Message: '',
	  isOldNew: 0, // 是否依旧换新 0否 1是
	  isCompleteOld: 0, // 是否完成收旧 0否 1是
	  oldMachCategorys: [],// 旧机品类列表
	  oldMachCategory: '' ,// 旧机品类
	  oldMachCategoryName: '',
	  oldMachTreatWays: [], // 旧机处理途径
	  oldMachTreatWay: '' ,// 旧机处理途径
	  oldMachTreatWayName: '',
      companyList:[],
      fetching:false,
      provinceId:'',
      cityId:'',
      districtId:'',
      adressObj:{}
    };
  },
  computed: {
    ...mapState({
      isFenxiaoOrder: state => state.user.userInfo.account.marketModels,
      toPath: state => state.user.toPath
    })
  },

  mounted() {
    //组件
    // this.columns = tableUtil.columns;
    // 配送方式
    // this.getPS();
    this.getDelivery()
    // 获取销售组织list
    this.getOrg();
    // 省市区
    this.carList();
    this.getywy();
    // 开票方
    this.getkpf();

    var now = new Date();
    var year = now.getFullYear(); //得到年份
    var month = now.getMonth() + 1; //得到月份
    var date = now.getDate(); //得到日期
    // var day = now.getDay(); //得到周几
    var hour = now.getHours(); //得到小时
    var minu = now.getMinutes(); //得到分钟
    var sec = now.getSeconds(); //得到秒
    var time =
      year + "-" + month + "-" + date + " " + hour + ":" + minu + ":" + sec;

    var time2 = year + "-" + month + "-" + date + " " + hour + ":" + '00';
    this.startTime = time2
    // 提示文字修改
    this.getMessage()
    this.getMessage1()
    this.getMessage2()
	this.getOldMachCategory();
	this.getOldMachTreatWays();
  },
  watch: {
    // receiveID: {
    //   immediate: true,
    //   handler(newValue) {
    //     this.isEnableOrNotBuchaUpdate();
    //   }
    // }
    trans: {
      immediate: true,
      handler(newValue) {


      }
    },
    XSLX: {
      immediate: true,
      handler(newValue) {

      }
    },
    fhckId: {
      immediate: true,
      handler(newValue) {

      }
    },

  },
  methods: {
    getAdress(value) {
      let id = null
      if(this.proObj.townCode) {
        id = this.proObj.townCode
      }else if (this.proObj.cityCode) {
        id = this.proObj.cityCode
      }else if (this.proObj.proviceCode) {
        id =this.proObj.proviceCode
      } else {
        id = 0
      }

      this.userDetailAddress = value;
      let a  = {
        searchstr: this.userDetailAddress,
        region:id
      }
      let b = {
        searchstr: this.userDetailAddress,
      }
      let data = id !== 0 ? a : b
      this.fetching = true;
      getAdressapi(data).then(res => {
        if (res.data.data.pois.length > 0 && res.data.data.pois !== null) {
          this.companyList = [...new Set(res.data.data.pois)]; //缓存列表数据，前端分页
        } else {
          this.companyList =[]
        }

        this.fetching = false;
      });

    },
    //查询数据
    searcha() {

    },
    async sumbitaaa() {
      this.isLoading = true
      this.isLoading = false
      this.panduanfreeTip()
      return
    },
    // 提示信息 
    getMessage() {
      const msg = this.$configTotal({
        route: this.$route.fullPath,
        id: '14187495767'
      })
      // this.$message.info(msg && msg.msgDesc);
      this.show1Message = msg && msg.msgDesc

    },
    getMessage1() {
      const msg = this.$configTotal({
        route: this.$route.fullPath,
        id: '14187495772'
      })
      // this.$message.info(msg && msg.msgDesc);
      this.show2Message = msg && msg.msgDesc
    },
    getMessage2() {
      const msg = this.$configTotal({
        route: this.$route.fullPath,
        id: '14187495874'
      })
      // this.$message.info(msg && msg.msgDesc);
      this.show3Message = msg && msg.msgDesc
    },
    //  判断商品是否为免标识
    panduanfreeTip() {
      // let mian = []
      // this.productList.forEach(item => {
      //   if (item.productLabel && item.productLabel.indexOf('15691143850') !== -1) {
      //     mian.push(item)
      //   }
      // })
      // if (mian.length > 0) {
      //   this.visibleshop = true
      // } else 
      if (this.XSLX == 'retail' && (this.fhckType == 20 || this.fhckType == '20')) {
        this.visibleshopLS = true
      } else if (this.XSLX == 'engineering' && (this.fhckType == 20 || this.fhckType == '20')) {
        this.visibleshopGC = true
      } else {
        this.submitOrder()
      }
    },
    handleRemove(file) {
      const index = file;
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {

      if ((file.size) / 1024 > 5120) {
        this.$message.warning('附件大小不能大于5M')
        return false;
      } else {
        // 上传附件   调接口
        let account = this.$store.state.user.userInfo ? this.$store.state.user.userInfo.account.account : JSON.parse(localStorage.getItem("userInfo")).account.account;
        let cisCode = this.$store.state.user.userInfo ? this.$store.state.user.userInfo.customer.cisCode : JSON.parse(localStorage.getItem("userInfo")).customer.cisCode;
        let formData = new FormData()
        formData.append('cisCode', cisCode);
        formData.append('businessType', 'retailOrder')
        formData.append('file', file);
        const BASE_API = dmsPublicPath;
        let config = {
          headers: { 'content-type': 'multipart/form-data', },
        }
        axios.post(BASE_API + '/wechatEntrance/entrance.do' + '?account=' + account + '&method=uploadAttachment' + '&type=1', formData, config).then(res => {
          let resResult = JSON.parse(res.data)
          if (resResult.code == "0") {
            this.fileList = [...this.fileList, file]
            this.fileList.forEach(item => {
              item.size2 = ((item.size) / 1024).toFixed(2)
            })
            this.ImgIds.push(resResult.data[0].id)
          } else {
            this.$message.warning('上传失败', resResult.msg)
          }
        })

        return false;
      }
      // this.fileList = [...this.fileList, file];
      // return false;
    },
    handleChange({ fileList }) {



      let fileList1 = [...this.fileList];
      this.fileList = fileList1;
      this.fileList.forEach(item => {
        item.size2 = ((item.size) / 1024).toFixed(2)
      })
    },

    getSeverInfo() {
      if (this.fhckType != 20) {
        return
      }
      this.pageLoadFlag = true;
      this.FWlist = [];
      this.fwlbId = [];
      // 根据销售组织和四级地址获取服务列表数据
      let data = {
        orgCode: this.receiveID,
        warehouseCode: this.receiveFHID,
        provinceCode: this.proObj.proviceCode,
        cityCode: this.proObj.cityCode,
        countyCode: this.proObj.townCode,
        townCode: this.proObj.zCode,

      }
      getSeverList(data).then(res => {

        this.pageLoadFlag = false;
        // 获取服务列表数据
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        // 目前接口还不通,需要修改赋值参数
        this.FWlist = data.data;

        // 默认选中
        if (this.FWlist && this.FWlist.length > 0) {
          for (const item of this.FWlist) {
            if (item.isDefault == 1 && item.isSupport == 1) {
              this.fwlbId.push(item.serviceCode);
            };
          };
        }

      }).catch(res => {

        this.pageLoadFlag = false;
      })
    },
    // handleChangeAddressFW(value, e) {
    //   // 获取选中的服务列表的id
    //   this.serverBox = (e.map(item=>item.key)).toString()
    // },
    // 送装一体修改选项
    changeSZtype(e) {
      this.szytType = this.SZOptions[e.target.value].id;
    },
    // isEnableOrNotBuchaUpdate() {
    //   if (!this.receiveID) {
    //     return;
    //   }
    //   let data = {
    //     cisCode: this.$store.state.user.userInfo.customer.cisCode,
    //     orgCode: this.receiveID
    //   };
    //   isEnableOrNotBucha(data).then(res => {
    //     if(!res.data){
    //       return
    //     }
    //     const resData = JSON.parse(res.data);
    //     this.isEnableOrNot = resData.data;
    //   });
    // },

    getMetarial() {
      if (!this.receiveXSID || !this.receiveID) {
        this.materialGroup = [];
        return;
      }
      findMaterialByStore({
        storeCode: this.receiveXSID,
        orgId: this.receiveID
      }).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        this.materialGroup = data.materialGroup;
      });
    },
    getCustromType(){
      // 17451是渠道采购，17452 自营或者17453 海信采购 （可能同时存在）
      // 自营
      if (this.isFenxiaoOrder.indexOf("17452") !== -1 &&  this.isFenxiaoOrder.indexOf("17451") == -1 && this.isFenxiaoOrder.indexOf("17453") == -1){
        return 5
      }
      if (
        // 代理
        (this.isFenxiaoOrder.indexOf("17452") !== -1 ||
          this.isFenxiaoOrder.indexOf("17453") !== -1) &&
        this.isFenxiaoOrder.indexOf("17451") == -1
      ) {
        return 1
      } else if (
        //分销
        this.isFenxiaoOrder.indexOf("17451") !== -1 &&
        this.isFenxiaoOrder.indexOf("17452") == -1 &&
        this.isFenxiaoOrder.indexOf("17453") == -1
      ) {
        return 2
      } else if (
        // 分销直营 不显示
        //共存 包括分销或者不包括直营 并且是海信 return 3 显示按钮
        (this.isFenxiaoOrder.indexOf("17451") !== -1 || this.isFenxiaoOrder.indexOf("17452") == -1) && this.isFenxiaoOrder.indexOf("17453") !== -1
      ) {
        return 3
      } else if (this.isFenxiaoOrder.indexOf("17451") !== -1 && this.isFenxiaoOrder.indexOf("17452") !== -1&& this.isFenxiaoOrder.indexOf("17453") == -1){
        return 4
      }



    },
    //搜索按钮事件
    search() {
      if (!this.searchIpt || this.searchIpt.length < 2) {
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000066'
        })
        this.$message.info(msg && msg.msgDesc);
        // this.$message.info("请输入至少两个字符进行搜索！");
        return;
      }
      this.pager.pageNo = 1;
      this.getData();
    },
    pageSearch(pager) {
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.getData();
    },
    //调接口获取数据
    getData() {
      this.tabelIsLoading = true;
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          model: this.searchIpt,
          colour: "",
          warehouseId: this.receiveFHID,
          invStatusId: "",
          isLock: "",
          dealerMaterialGroupFlag: "",
          materialGroupCode: "",
          materialGroupName: "",
          gicWarehouseType: "",
          invStatusType: "",
          invType: "",
          bigQtyMin: 0,
          orgId: this.receiveID
        },
        page: {
          pageNo: this.pager.pageNo,
          pageSize: this.pager.pageSize
        }
      };
      getProductList(data).then(res => {
        this.tabelIsLoading = false;
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        const start = (this.pager.pageNo - 1) * this.pager.pageSize;
        data.data.forEach(it => {
          it.borderedQty = 1;
          it.invStatus = it.invStatusId;
          it.bigUom = it.uom;
          it.uid = this.genID(10);
        });
        this.selRows = []; //切换页码清空勾选的行
        this.onlyShowChecked = false;
        this.pager.count = data.page.totalCount;
        this.dataCache = data.data; //缓存列表数据，前端分页
        this.dataList = data.data.slice(start, start + this.pager.pageSize);

      });
      // }
    },
    //生成不重复id/后端返回的列表没有唯一键
    genID(length) {
      return Number(
        Math.random()
          .toString()
          .substr(3, length) + Date.now()
      ).toString(36);
    },
    editRow(item) {
      this.rowSelectionType = "radio";
      this.searchIpt = item.model;
      this.selRow = item;
      this.isVisible = true;
      this.getData();
    },
    addProduct(productList) {
      productList = productList.filter(item => {
        if (item.productCode && item.productName) {
          return true;
        } else {
          // this.$message.warning("存在信息不全的产品，已去除！");
          const msg = this.$configTotal({
            route: this.$route.fullPath,
            id: '14180000067'
          })
          this.$message.warning(msg && msg.msgDesc);
          return false;
        }
      });
      if (this.rowSelectionType == "checkbox") {
        const models = this.productList.map(it => it.productCode);
        productList.forEach(it => {
          if (!models.includes(it.productCode)) {
            it.checked = true;
            it.itemId = "";
            it.bprice = it.retailPrice
            it.bprice1 = it.retailPrice
            models.push(it.productCode);
            this.productList.push(it);
          } else {
            this.productList.forEach(item => {
              if (item.productCode == it.productCode) {
                item.borderedQty += 1
              }
            })
            // this.$message.warning("产品重复,重复产品已去除!");
            // const msg =  this.$configTotal({
            //   route: this.$route.fullPath,
            //   id:'14180000068'
            // })
            // this.$message.warning(msg&&msg.msgDesc);
          }
        });
      } else {
        if (productList.length > 0) {
          let pro = productList[0];
          const index = this.productList.findIndex(
            it => it.productCode == this.selRow.productCode
          );
          pro = {
            ...pro,
            checked: true,
            itemId: "",
            bprice: this.productList[index].bprice
          };
          this.$set(this.productList, index, pro);
        }
      }


    },
    getywy() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        type: "ywy"
      };
      findBaseData(data).then(res => {
        var obj = JSON.parse(res.data);
        this.ywy = Object.keys(obj.data[0])[0];
      });
    },
    // 开票方
    getkpf() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        type: "kpf"
      };
      findBaseData(data).then(res => {
        var obj = JSON.parse(res.data);
        this.kpf = Object.keys(obj.data[0])[0];
      });
    },
    // 销售组织list
    getOrg() {
      let data = {
        customerCisCode: this.$store.state.user.userInfo.customer.cisCode
      };
      getOrgList(data).then(res => {
        var realDate = JSON.parse(res.data);

        // 销售组织
        var XSZZ = [];
        for (let item of realDate.data) {
          for (let key in item) {
            XSZZ.push({
              key,
              value: item[key]
            });
          }
        }
        if (XSZZ.length > 0) {

          this.receive = XSZZ[0].value;
          this.receiveID = XSZZ[0].key;
          this.orderDis = XSZZ;
          this.xszhId = XSZZ[0].value;
          //获取基本信息
          this.basicMessage(XSZZ[0].key);
        }
      });

    },
    // 零售订单基础信息
    basicMessage(orgId) {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        orgId
      };
      this.pageLoadFlag = true;
      getBasicMessage(data).then(res => {
        this.pageLoadFlag = false;
        var realDate = JSON.parse(res.data);

        this.realObj = realDate;
        // 零售客户
        // 销售门店
        var XSMDS = [];
        for (let key in realDate.baseInfo.storeInfo) {
          XSMDS.push({
            key,
            value: realDate.baseInfo.storeInfo[key].name,
            isSpeclalShop:realDate.baseInfo.storeInfo[key].isSpeclalShop
          });
        }
        if (XSMDS.length > 0) {
          this.receiveXS = XSMDS[0].value;
          this.xsmdId = XSMDS[0].value;
          this.receiveXSID = XSMDS[0].key;
          this.XSMD = XSMDS;
        } else {
          this.receiveXS = "";
          this.xsmdId = "";
          this.receiveXSID = "";
          this.XSMD = [];
        }

        this.getMetarial();

        this.getWarehouseList();
        // // 发货仓库
        // var FHCK = [];
        // for (let key in realDate.baseInfo.warehouse) {
        //   FHCK.push({
        //     key,
        //     value: realDate.baseInfo.warehouse[key]
        //   });
        // }
        // if (FHCK.length > 0) {
        //   this.receiveFHS = FHCK[0].value;
        //   this.fhckId = FHCK[0].value;
        //   this.receiveFHID = FHCK[0].key;
        //   this.receiveFH = FHCK;
        // } else {
        //   this.receiveFHS = "";
        //   this.fhckId = "";
        //   this.receiveFHID = "";
        //   this.receiveFH = [];
        // }

        // 收货地址
        // 省
        var adres = realDate.baseInfo.address;
        let pro = Object.keys(adres.province)[0];
        let citykey = Object.keys(adres.city)[0];
        let countrykey = Object.keys(adres.country)[0];
        let townkey = Object.keys(adres.town)[0];
        this.proObj.proviceCode = pro;
        this.proObj.cityCode = citykey;
        this.proObj.townCode = countrykey;
        this.proObj.zCode = townkey;
        this.receivehisSH =
          adres.province[pro] +
          adres.city[citykey] +
          adres.country[countrykey] +
          adres.town[townkey];
        this.sheng = adres.province[pro];
        this.checkCity = adres.city[citykey];
        this.checkTownCode = adres.country[countrykey];
        this.checkXZ = adres.town[townkey];
        // this.carList();
        this.choseCity(this.proObj.proviceCode);
        this.getqu(this.proObj.townCode);
        this.getTowns(this.proObj.cityCode);

      }).catch(res => {
        console.log(res)
        this.pageLoadFlag = false;
      });
    },

    getWarehouseList() {
      // 根据销售组织获取仓库类型
      //发货地址改动接口 -》 根据仓库类型（20共享仓，70原仓）判断是否选择服务列表-》20统仓 选
      this.pageLoadFlag = true;
      getWarehouseList({ orgId: this.receiveID }).then(res => {
        // getWarehouseList().then(res => {
        this.pageLoadFlag = false;
        let data_ = JSON.parse(res.data);
        // 发货仓库
        let FHCK = [];
        for (let item of data_.data) {
          FHCK.push({
            key: item.cId,
            value: item.name,
            type: item.type
          });
        }
        if (FHCK.length > 0) {
          this.receiveFHS = FHCK[0].value;
          this.fhckId = FHCK[0].value;
          this.receiveFHID = FHCK[0].key;
          this.receiveFH = FHCK;

          //仓库类型 -》 20统舱，70原仓
          this.fhckType = FHCK[0].type;

          //根据仓库的默认第一项判断获取服务列表 及是否展示
          if (this.fhckType == 20) {
            this.getSeverInfo();

          }
        } else {
          this.receiveFHS = "";
          this.fhckId = "";
          this.receiveFHID = "";
          this.receiveFH = [];
          this.fhckType = '';
          this.fwlbId = [];
        }

      }).catch(res => {
        console.log(res)
        this.pageLoadFlag = false;
      });
    },
    onChange(selKeys, selRows) {
    },

    // 销售门店
    receiveAddressXS() {
      this.receiveAddXS = !this.receiveAddXS;
    },
    // 发货仓库
    receiveAddressFH() {
      this.receiveAddFH = !this.receiveAddFH;
    },
    handleChangeAddressFH(value, e) {

      this.receiveFHS = value;

      // 发货仓库 选中 id
      this.receiveFHID = e.key;
      // this.SAddressChange(e.key)
      this.receiveAddFH = !this.receiveAddFH;
      this.productList = []

      for (const item of this.receiveFH) {
        if (item.key == e.key) {

          this.fhckType = item.type;
          debugger
          // 如果仓库类型为同仓并且 配送方式为工程  不显示服务列表 并且 传值为空
          if (this.fhckType == "20" && this.trans == '07') {

            this.FWlist = []
            this.fwlbId = []
            return

          } else if (this.fhckType == "20" && this.trans !== '07') {
            this.getSeverInfo()
            return
          } else {
            this.fwlbId = [];
          }


          // if (item.type == 20) {
          //   // 获取服务列表
          //   this.ckType = 20
          //   this.getSeverInfo()
          // } else {
          //   this.fwlbId = [];
          // }

          return
        }
      }


    },
    handleChangeAddressXS(value, e) {
      this.receiveXS = value;
      // 收货地址 选中 id
      this.receiveXSID = e.key;
      this.getMetarial();
      // this.SAddressChange(e.key)
      this.receiveAddXS = !this.receiveAddXS;
      // this.getFHCK(e.key)
    },
    // dms配送方式
    getPS() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account
      };
      getDeliveryMode(data).then(res => {
        var obj = JSON.parse(res.data);
        // // 配送方式
        // this.deliveryOptions = res.data.cartOrder.deliveryList;
        // // 默认配送方式id
        // this.trans = res.data.cartOrder.deliveryList[0].id
        var arr = [];
        for (let item of obj.data) {
          for (let key in item) {
            arr.push({
              id: key,
              propertyName: item[key]
            });
          }
        }
        this.deliveryOptions = arr;
        this.trans = arr[0].id;

      });
    },
    // cis查询配送方式接口 
    getDelivery() {
      let data = {
        type: this.XSLX,
        sortOrder: 'asc'
      }
      getDeliveryType(data).then(res => {
        if (res.data.list && res.data.list.length > 0) {
          this.deliveryOptions = res.data.list;
          this.trans = this.deliveryOptions[0].code;

        }
      })
    },
    carList() {
      let data = {};
      getProvice(data).then(res => {

        // this.choseCity(this.sheng||res.data[0].provinceCode);
        this.provices = res.data;
        // this.proObj.proviceCode = res.data[0].provinceCode;
      });
    },
    // 省 选择
    handleProvinceChange(value, e) {
      this.proObj.proviceCode = e.key;
      this.sheng = value;
      this.choseCity(value);
      this.proObj.cityCode = "";
      this.checkCity = "";
      this.proObj.zCode = "";
      this.checkTownCode = "";
      this.proObj.townCode = "";
      this.checkXZ = "";

      // 获取服务列表
      this.getSeverInfo()

    },
    // 获取城市
    choseCity(code) {
      // debugger
      let data = {
        proviceCode: code
      };
      cityChange(data)
        .then(res => {
          this.getCity = res.data;
          // this.checkCity = res.data[0].cityName;
          //获取城镇区
          this.getTowns(this.this.checkCity || res.data[0].cityCode);
          // this.proObj.cityCode = res.data[0].cityCode;
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 获取城镇区
    getTowns(code) {
      let data = {
        cityCode: code
      };
      townChange(data)
        .then(res => {
          this.getTown = res.data;
          // this.checkTownCode = res.data[0].districtName
          // this.proObj.townCode = res.data[0].districtCode;
          this.getqu(this.proObj.townCode || res.data[0].districtCode);
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 获取城镇下一级 town
    getqu(code) {
      let data = {
        districtCode: code
      };
      getQu(data)
        .then(res => {
          // this.checkXZ = res.data[0].townName;
          this.qu = res.data;
          // this.proObj.zCode = res.data[0].townCode;
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 城市选择
    handleCityChange(e) {
      this.proObj.cityCode = e;
      this.proObj.zCode = "";
      this.checkTownCode = "";
      this.proObj.townCode = "";
      this.checkXZ = "";
      this.getTowns(e);
      // 获取服务列表
      this.getSeverInfo()

    },
    // 城镇选择
    handleTownChange(e) {
      this.proObj.townCode = e;
      this.getqu(e);
      this.proObj.zCode = "";
      this.checkXZ = "";
      // 获取服务列表
      this.getSeverInfo()

    },
    // 城镇下一级 选择
    handleZhenChange(e) {
      this.proObj.zCode = e;
      // this.checkXZ =
      this.getSeverInfo()

    },
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      this.visible = false;
    },
    onGiveType(e) {
      this.trans = e.target.value;
      // 如果仓库类型为同仓并且 配送方式为工程  不显示服务列表 并且 传值为空
      if (this.fhckType == "20" && this.trans == '07') {

        this.FWlist = []
        this.fwlbId = []
        return

      } else if (this.fhckType == "20" && this.trans !== '07') {
        this.getSeverInfo()
        return
      }
    },
    // 分销商 分销地址切换获取手机号
    showAnotheer() {
      getPhone({
        sendToId: this.distributionAdd.DISCODE
      }).then(() => { });
    },
    onSubmit() {
      //form
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 点击选择开票户头
    choseAccount() {
      // axios请求后台开票户头
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 用户级联选择改变
    cityChange(value) {
    },
    // 销售类型
    // 销售类型
    onGiveTypeLX(e) {

      this.XSLX = this.saleOptions[e.target.value].id;
      this.getDelivery()


    },
	// 是否依旧换新
	oldNewChange(e) {
		this.isOldNew = e.target.value;
	},
	// 完成收旧
	completeOldChange(e) {
		this.isCompleteOld = e.target.value;
	},
    // dms 订单提交
    submitOrder() {
      if (!this.receiveID) {
        // this.$message.warning("请选择销售组织");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000070'
        })
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
	  if(this.isOldNew == 1) {
		  if(!this.oldMachCategory) {
			   this.$message.warning('请选择旧机品类');
        this.isLoading = false
			   return
		  }
		  if(!this.oldMachTreatWay) {
			  this.$message.warning('请选择旧机处理途径');
        this.isLoading = false
			  return
		  }
	  }
	  // return
      if (!this.business.code) {
        // this.$message.warning("请输入零售客户手机号");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000077'
        })
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (!this.business.name) {
        // this.$message.warning("请输入零售客户姓名");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000078'
        })
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (!this.receiveXSID) {
        // this.$message.warning("请选择销售门店");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000079'
        })
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (!this.trans) {
        // this.$message.warning("请选择配送方式");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000071'
        })
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (
        !(
          this.proObj.zCode &&
          this.proObj.proviceCode &&
          this.proObj.cityCode &&
          this.proObj.townCode
        )
      ) {
        // this.$message.warning("请选择直配地址");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000080'
        })
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (!this.userDetailAddress) {
        // this.$message.warning("请输入详细地址");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000081'
        })
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (!this.XSLX) {
        // this.$message.warning("请选择销售类型");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000073'
        })
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (!this.receiveFHID) {
        // this.$message.warning("请选择发货仓库");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000074'
        })
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      // if (!this.startTime) {
      //   this.$message.warning('请选择期望到货日期');
      //   return;
      // }
      // 当前日期
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth() + 1; //得到月份
      var date = now.getDate(); //得到日期
      // var day = now.getDay(); //得到周几
      var hour = now.getHours(); //得到小时
      var minu = now.getMinutes(); //得到分钟
      var sec = now.getSeconds(); //得到秒
      var time =
        year + "-" + month + "-" + date + " " + hour + ":" + minu + ":" + sec;

      var time2 = year + "-" + month + "-" + date;

      const salesOrderItem = this.productList.filter(it => it.checked);
      // salesOrderItem.forEach(it=>{
      //   if(!this.isEnableOrNot){
      //     it.invStatusType = ''
      //   }
      // })
      if (salesOrderItem.length < 1) {
        // this.$message.warning("请至少选择一个商品！");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000075'
        })
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      for (let i = 0; i < this.productList.length; i++) {
        const item = this.productList[i];
        if (item.checked) {
          if (!item.bprice) {
            this.$message.warning(`请检查第${i + 1}个商品价格！`);
            this.isLoading = false
            return;
          }
          if (!item.borderedQty) {
            this.$message.warning(`请检查第${i + 1}个商品数量！`);
            this.isLoading = false
            return;
          }
        }
      }
      // salesOrderItem.forEach(it => {
      //   it.bdemandQty = it.borderedQty
      // })
      let salesOrder = {
        dataSource: 'xtw_pc',
        // 当前日期
        documentDate: time,
        retailType: this.XSLX,
        isOutbound: 1,
        discountAmount: 0,
        provinceId: this.proObj.proviceCode,
        cityId: this.proObj.cityCode,
        townId: this.proObj.zCode,
        countryId: this.proObj.townCode,
        message: this.BZvalue,
        customerName: this.business.name,
        orgId: this.receiveID,
        // 开票方id
        billFromId: this.kpf,
        // 业务员编码
        sellerCode: this.ywy,
        // 订单金额
        totalAmount: this.$refs.productList.getTotalMoney(),
        // 销售单明细，商品行信息
        salesOrderItem: salesOrderItem,
        // 收款金额
        payAmount: this.$refs.productList.getTotalMoney(),
        edt: this.startTime,
        // edt: time2,
        phone: this.business.code,
        warehouseId: this.receiveFHID,
        deliveryMode: this.trans,
        fullAddress: this.userDetailAddress,
        // 订单id
        id: "",
        // 门店编码
        storeCode: this.receiveXSID,
        // 是否送装一体
        zoneB2cService: this.fwlbId.join(','),
        isGroup: "0",//是否套购暂不用但必传
        attachmentIds: this.ImgIds.join(","),
        isOldNew: this.isOldNew,
        isCompleteOld: this.isCompleteOld,
        oldMachCategory: this.oldMachCategory,
        oldMachCategoryName: this.oldMachCategoryName,
        oldMachTreatWay: this.oldMachTreatWay,
        oldMachTreatWayName: this.oldMachTreatWayName
      };
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        salesOrder: salesOrder,
        status: "submitted"
      };
      this.isLoading = true;

      retailOrder(data)
        .then(res => {
          var obj = JSON.parse(res.data);
          if (obj.code == "1") {
            this.$message.warning(obj.msg);
          } else if (obj.code == "0") {
            this.$router.push({
              path: "/confirm/inputSuccess",
              query: {
                b2bOrderCode: obj.msg,
                type: "retail",
                orgName: "订单号"
              }
            });
          }
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // 订单类型切换
    orderChange(type) {
      this.$emit("changeType", type);
    },
    // 时间更改
    handleTimeChange(e, defaultTime) {

      this.startTime = defaultTime;
    },
    // 控制可选时间范围
    disabledDate(current) {
      return current && current < moment().endOf('day');
    },
    // 获取子组件选中商品
    sonCheckList(data) {
      let cheacked = this.sonChectItme.indexOf(data);
      if (this.sonChectItme.indexOf(data) == -1) {
        this.sonChectItme.push(data);
      } else {
        this.sonChectItme.splice(cheacked, 1);
      }

    },
    //   点击返回购物车
    goCar() {
      this.$router.push("/channelOrder/orderList?type=retail");
    },
    // 收货地址点击选择
    receiveAddress() {
      this.receiveAdd = !this.receiveAdd;
    },
    // 销售组织
    handleChangeAddress(value, e) {
      this.receive = value;
      // 收货地址 选中 id
      this.receiveID = e.key;
      // this.SAddressChange(e.key)
      this.receiveAdd = !this.receiveAdd;
      this.basicMessage(e.key);
      this.getWarehouseList();

    },
    // 收货地址
    hishandleChangeAddress(value, e) {
      this.test = value;
      this.distributionAdd.DISCODE = e.key;
      this.officeCode = e.key;
      this.hisenseOff = !this.hisenseOff;
      this.showAnotheer();
    },
    // 切换收货地址
    SAddressChange(id) {
      showAnother({
        id
      })
        .then(res => {
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 分销商显示隐藏
    choseDistr() {
      this.distr = !this.distr;
    },
    // 切换分销商
    handleChangeDistr(value, e) {
      this.distrName = value;

      this.DISADDPARAMS.customerId = e.key;
      this.getDISADDList();
    },
    // 分销商切换获取分销商地址list
    getDISADDList() {
      let data = this.DISADDPARAMS;
      getDISAdd(data)
        .then(res => {

          this.choseDistribution = res.data;
          this.distributionAdd.DISCODE = res.data[0].id;
        })
        .catch(err => {
          console.log(err);
        });
    },
    distrAddShow() {
      this.distrAdd = !this.distrAdd;
    },
    // 选择经销商地址
    handleChangeDistrAdd(value, e) {
      this.distributionAdd.DISCODE = e.data.key;
      this.distributionAdd.DISADD = value;

      this.distrAddress = value;
      getPhone({
        sendToId: e.key
      }).then(res => {
        console.log(res);
      });
    },
    // 分销商地址切换获取联系人
    getPeople() {
      getPhone();
    },
    // 弹框
    openAdd() {
      if (!this.receiveID) {
        // this.$message.warning("请选择销售组织");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000070'
        })
        this.$message.warning(msg && msg.msgDesc);
        return;
      }
      if (!this.receiveFHID) {
        this.$message.warning('请先选择发货仓库！');
        return
      }
      this.rowSelectionType = "checkbox";
      this.isVisible = true;
      if (!this.searchIpt || this.searchIpt.length < 2) {
        this.selRows = []; //切换页码清空勾选的行
        this.onlyShowChecked = false;
        this.pager.count = 0;
        this.dataCache = []; //缓存列表数据，前端分页
        this.dataList = []
        return;
      }
      this.getData();
    },
    // 海信办事处
    hisenseOffice() {
      this.hisenseOff = !this.hisenseOff;
    },
	// 获取旧机品类
	getOldMachCategory() {
		let data = {
			cisCode: this.$store.state.user.userInfo.customer.cisCode,
			userAccount: this.$store.state.user.userInfo.account.account,
			type:"xsddjjpl",
		}
		findBaseData(data).then(res => {
			res = JSON.parse(res.data).data;
			for(let item of res){
				let obj = {}
				let key = Object.keys(item)[0];
				obj.key = key;
				obj.value = item[key]
				this.oldMachCategorys.push(obj)
			}
			
		})
	},
	// 获取旧机处理途径
	getOldMachTreatWays() {
		let data = {
			cisCode: this.$store.state.user.userInfo.customer.cisCode,
			userAccount: this.$store.state.user.userInfo.account.account,
			type:"xsddjjcltj",
		}
		findBaseData(data).then(res => {
			res = JSON.parse(res.data).data;
			for(let item of res){
				let obj = {}
				let key = Object.keys(item)[0];
				obj.key = key;
				obj.value = item[key]
				this.oldMachTreatWays.push(obj)
			}
		})
	},
	// 旧机品类修改
	oldMachCategoryChange(e) {
		for(let item of this.oldMachCategorys) {
			if(e == item.key) {
				this.oldMachCategoryName = item.value;
				return
			}
		}
	},
	// 旧机处理途径修改
	oldMachTreatWayChange(e) {
		this.oldMachTreatWay = e;
		for(let item of this.oldMachTreatWays) {
			if(e == item.key) {
				this.oldMachTreatWayName = item.value;
				return
			}
		}
	}
  }
};
